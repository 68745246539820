import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Login'

import Home from '@/components/Home'
import Profil from '@/components/Profil'
import Jadwal from '@/components/Jadwal'
import Pemberkasan from '@/components/Pemberkasan'
import Soal from '@/components/Soal'
import Ujian from '@/components/Ujian'
import HasilUjian from '@/components/HasilUjian'
import Sertifikat from '@/components/Sertifikat'
import Wisuda from '@/components/Wisuda'
import Pembayaran from '@/components/Pembayaran'
import KidoraMenu from '@/components/KidoraMenu'
import Lowongan from '@/components/Lowongan'
import Marketing from '@/components/Marketing'
import TermCondition from '@/components/TermCondition.vue'
import Pendaftar from '@/components/Pendaftar'
import SiswaDetail from '@/components/SiswaDetail'

import PraktekTerbang from '@/components/PraktekTerbang'
import PaketWisata from '@/components/PaketWisata'
import PaketWisataDetail from '@/components/PaketWisataDetail'
import HistoryBookingWisata from '@/components/HistoryBookingWisata'
import Tiket from '@/components/Tiket'
import Search from '@/components/Search'
import Booking from '@/components/Booking'
import Payment from '@/components/Payment'
import History from '@/components/History'
import HistoryDetail from '@/components/HistoryDetail.vue'

import Scan from '@/components/Scan.vue'
import Angket from '@/components/Angket.vue'
import Refund from '@/components/Refund.vue'
import KelasPrioritas from '@/components/KelasPrioritas.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/profil',
      name: 'Profil',
      component: Profil
    },
    {
      path: '/pemberkasan',
      name: 'Pemberkasan',
      component: Pemberkasan
    },
    {
      path: '/jadwal',
      name: 'Jadwal',
      component: Jadwal
    },
    {
      path: '/ujian',
      name: 'Ujian',
      component: Ujian
    },
    {
      path: '/soal',
      name: 'Soal',
      component: Soal
    },
    {
      path: '/hasil-ujian',
      name: 'HasilUjian',
      component: HasilUjian
    },
    {
      path: '/sertifikat',
      name: 'Sertifikat',
      component: Sertifikat
    },
    {
      path: '/wisuda',
      name: 'Wisuda',
      component: Wisuda
    },
    {
      path: '/praktek-terbang',
      name: 'PraktekTerbang',
      component: PraktekTerbang
    },
    {
      path: '/paket-wisata',
      name: 'Paket Wisata',
      component: PaketWisata
    },
    {
      path: '/paket-wisata/:id',
      name: 'Paket Wisata Detail',
      component: PaketWisataDetail
    },
    {
      path: '/history-booking-wisata',
      name: 'History Booking Wisata',
      component: HistoryBookingWisata
    },
    {
      path: '/tiket',
      name: 'Tiket',
      component: Tiket
    },
    {
      path: '/search',
      name: 'Search',
      component: Search
    },
    {
      path: '/booking',
      name: 'Booking',
      component: Booking
    },
    {
      path: '/payment',
      name: 'Payment',
      component: Payment
    },
    {
      path: '/history',
      name: 'History',
      component: History
    },
    {
      path: '/history/:id',
      name: 'HistoryDetail',
      component: HistoryDetail
    },
    {
      path: '/pembayaran',
      name: 'Pembayaran',
      component: Pembayaran
    },
    {
      path: '/kidora',
      name: 'Kidora',
      component: KidoraMenu
    },
    {
      path: '/lowongan/:id',
      name: 'Lowongan',
      component: Lowongan
    },
    {
      path: '/scan',
      name: 'Scan',
      component: Scan
    },
    {
      path: '/marketing',
      name: 'Marketing',
      component: Marketing
    },
    {
      path: '/marketing/aktivasi',
      name: 'Aktivasi',
      component: TermCondition
    },
    {
      path: '/pendaftar',
      name: 'Pendaftar',
      component: Pendaftar
    },
    {
      path: '/kelas-prioritas',
      name: 'KelasPrioritas',
      component: KelasPrioritas
    },
    {
      path: '/pendaftar/:status',
      name: 'Pendaftar',
      component: Pendaftar
    },
    {
      path: '/siswa/:id',
      name: 'SiswaDetail',
      component: SiswaDetail
    },
    {
      path: '/angket',
      name: 'Angket',
      component: Angket
    },
    {
      path: '/refund',
      name: 'Refund',
      component: Refund
    },
  ]
})
