<template>
  <div id="app">
    <router-view />

    <div class="modal" v-show="showModal">
      <div class="modal-body">
        <h3>PENTING</h3>
        <form @submit.prevent="updateWA()">
          <div class="form-group">
            <label>Silakan update No WA Orang Tua</label>
            <input type="tel" v-model="noWa" placeholder="Gunakan format 08xx" />
          </div>
          <div>
            <button type="submit" class="btn btn-" :disabled="loading">
              {{ loading ? "MENYIMPAN.." : "UPDATE" }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "./api/Api";
import User from "@/components/icons/User.vue";

export default {
  name: "App",
  components: {
    User,
  },
  data() {
    return {
      showModal: false,
      loading: false,
      noWa: "08",
    };
  },
  methods: {
    updateWA() {
      const data = {
        no_wa: this.noWa,
        id: this.$store.state.settings.user.id,
      };
      console.log(data);

      if (!this.noWa || this.noWa == "08") {
        alert("No WA harus diisi");
        return;
      }

      this.loading = true;

      api.post(`/v1/siswa/ProfilController/postWAorangtua`, data).then((r) => {
        location.reload();
      });
    },
    closeSidebar(e) {
      if (e.target.classList[0] == "sidebar-overlay") {
        this.$store.commit("settings/setSidebarOpen", false);
      }
    },
  },
  mounted() {
    if (this.$route.path != "/login") {
      if (!this.$store.state.settings.user.id) {
        this.$router.push("/login");
      }

      const id_siswa = this.$store.state.settings.user.id;
      api
        .get(`/v1/siswa/ProfilController/getWAorangtua?id_siswa=${id_siswa}`)
        .then((r) => {
          if (!r.data.update_wa_orangtua) {
            this.showModal = true;
          }
        });
    }
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap');

body {
  margin: 0;
}

* {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary: #016DFE;
  --primary-lighter: #648dfd;
  --primary-darker: #3092e9;
  --orange: #ff551e;
}

@media screen and (min-width: 480px) {
  #app {
    max-width: 480px;
    margin: 0 auto;
    min-height: 100vh;
    box-shadow: 0px 0px 10px #ccc;
  }
}

form {
  .form-group {
    margin-bottom: 15px;

    label {
      display: block;
      font-size: 14px;
      margin-bottom: 4px;
    }

    select,
    input,
    textarea {
      padding: 10px;
      width: 100%;
      outline: none;
      border: 1px solid #999;
      border-radius: 4px;
      background: #fff;

      &:focus {
        border: 1px solid var(--primary);
      }

      &:disabled {
        border: 1px solid #ccc;
        color: #888;
      }
    }

    input,
    textarea {
      width: calc(100% - 22px);
    }

    .tgl-lahir {
      display: grid;
      grid-template-columns: 80px 1fr 120px;
      grid-column-gap: 10px;
    }
  }
}

button {
  transition: transform 0.3s ease;

  &:active {
    transform: scale(0.9);
  }
}

.btn-primary {
  background: var(--primary);
  color: #fff;
  padding: 15px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  outline: none;
  border: 0;
  border-radius: 6px;
  margin-bottom: 30px;
  font-weight: 600;

  &:active {
    background: var(--primary-darker);
  }

  &:disabled {
    background: var(--primary-lighter);
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);

  .modal-body {
    h3 {
      margin-top: 0;
    }

    width: 300px;
    height: max-content;
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;

    button {
      width: 100%;
      background: var(--primary);
      color: #fff;
      padding: 14px;
      border: 0;
      border-radius: 4px;
      outline: none;
      font-weight: 700;
      text-transform: uppercase;

      &:disabled {
        background: var(--primary-lighter);
        ;
      }
    }
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.2s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
